<template>
  <div>
    <v-container>
    <Header :title="$vuetify.lang.t('$vuetify.header_title.about')"/>
      <v-card flat>
        <div id="divConcept" class="yellow--text text--darken-4 text-center text-h5 font-weight-bold mt-12">CONCEPT</div>
        <div class="text-center font-weight-bold text-h5 mt-4">
          理想の家さがし<br/>
          私だちが支える
        </div>
        <div class="font-weight-medium text-subtitle-2 mt-4 pl-1">
          「はじめまして、RAKUMARU 不動産です」 <br />
          これまでの物件探しで、こんな経験はありませんか？ 
          ネットを見て問い合わせたけど、
          「条件に合う物件はありません」と言われ途方に
          暮れた・・・。もちろんタイミングもあるだろうけど、
          必要があって探しているのに、「ない」では困ってしまいますよね。
          RAKUMARU 不動産では、ご希望をじっくりお伺いし、お客様にかわって「物件探し」
          をいたします。時には長いお付き合いになるかもしれません。でも、最適な物件が見つか
          るまで、「もういいよ」とお声かけいただくまで、私たちはあきらめません。親切で正直
          は当たり前、でもその当然のことに真剣に取り組んでいるのが RAKUMARU です。
          お客様に納得していただけるまで、ご提案を続けてまいります。
        </div>
        <v-img src="../assets/about/ab1.jpg" class="mt-4"/>
        <v-img src="../assets/about/ab2.jpg" class="mt-2"/>
        
        <div id="divReason" class="yellow--text text--darken-4 text-center text-h3 font-weight-bold mt-12">REASON</div>
        <div class="text-center font-weight-medium text-subtitle-2">
          RAKUMARUがお客様に選ばれる理由
        </div>
        <v-img src="../assets/about/ab3.jpg" class="mt-2"/>

        <div class="yellow--text text--darken-4 text-h4 font-weight-medium mt-12">REASON 01</div>
        <div class="font-weight-medium text-subtitle-2 mt-4 pl-1">
          <div class="font-weight-medium text-h3 mt-4 mb-3">
            「ひと」
            <span class="orange--text text--lighten-4 text-h2 float-right mt-n2">01</span>
          </div>
          とのコミュニケーション 
          店舗物件をお探しの方の中には、「はじめて自分のお店を持つ」ということも。そんな
          場合、物件を契約してからがまさにスタートです。たとえば飲食店の場合、内装は？レイ
          アウトは？厨房機器はどうするか、保健所にホームページにやることが山のよう。そんな
          新規開店のサポートも精一杯させていただきます。
          <v-img src="../assets/about/reason01.jpg" class="mt-4"/>
        </div>

        <div class="yellow--text text--darken-4 text-h4 font-weight-medium mt-12">REASON 02</div>
        <div class="font-weight-medium text-subtitle-2 mt-4 pl-1">
          <div class="font-weight-medium text-h3 mt-4 mb-3">
            「まち」
            <span class="orange--text text--lighten-4 text-h2 float-right mt-n2">02</span>
          </div>
          とのコミュニケーション 
          「住みたい街ランキング」には入らなくても、住み続けたい魅力ある街はたくさんあり
          、ここでお店を開きたい理由があります。街の魅力を知っているからこそ、自信をもって
          おすすめできる。RAKUMARU 不動産では、東武東上線沿線の和光市から川越市を中心
          としたエリアにスポットを当てています。
          <v-img src="../assets/about/reason02.jpg" class="mt-4"/>
        </div>

        <div class="yellow--text text--darken-4 text-h4 font-weight-medium mt-12">REASON 03</div>
        <div class="font-weight-medium text-subtitle-2 mt-4 pl-1">
          <div class="font-weight-medium text-h3 mt-4 mb-3">
            「くらし」
            <span class="orange--text text--lighten-4 text-h2 float-right mt-n2">03</span>
          </div>
          のコミュニケーションへ
          コロナ禍で海外との往来は減ってしまったものの、超高齢化であり、かつ少子化する日
          本に住む我々は、外国人との共生を模索していかなければなりませんね。都心部と違い、
          徐々に増えていく外国人居住者を受け入れ、適応をフォローし、共生のためのコミュニテ
          ィーの役割を果たすこと、これも RAKUMARU 不動産の取り組みです。
          <v-img src="../assets/about/reason03.jpg" class="mt-4"/>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Header from "../components/Header.vue"

export default {
  name: "About",

  data: () => ({

  }),
  methods: {
    jump() {
      var position = this.$route.query.p
      if(position == 'concept') {
        document.getElementById('divConcept').scrollIntoView()
        //this.$vuetify.goTo('#divConcept',{duration: 300})
      } else if(position == 'reason') {
        document.getElementById('divReason').scrollIntoView()
        //this.$vuetify.goTo('#divReason',{duration: 300})
      }
    }
  },
  mounted() {
    if(!this.$route.query.p) {
      this.$vuetify.goTo(0,{duration: 0})
    } else {
      this.jump()
    }
  },
  watch: {
    '$route' (to,from) {
      if(!to.query.p) {
        this.$vuetify.goTo(0,{duration: 300})
        return
      }
      
      if(to.query.p != from.query.p) {
        this.jump()
      }
    }
  },
  components: {
    Header
  }

}
</script>
